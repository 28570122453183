<template>
  <div class="vps-detail">
    <Toast position="top-right" />
    <transition name="slide-fade">
      <page-block-stack :title="' '" class="vps-detail-backup__block">
        <!--        <plain-button-->
        <!--          slot="action"-->
        <!--          color="primary"-->
        <!--          nowrap-->
        <!--          @click="newFolder({ storage: tariff, storageid: storageid, folders: folders })"-->
        <!--        >-->
        <!--          {{ $t('addFolder') }}-->
        <!--        </plain-button>-->

        <!--          :lazy="true"-->
        <!--          :selection-mode="isBufferUse ? 'single' : 'checkbox'"-->
        <TreeTable
          :value="nodes"
          :loading="loader"
          :resizable-columns="true"
          column-resize-mode="fit"
          sort-mode="single"
          :filters="filters"
          filter-mode="lenient"
          selection-mode="checkbox"
          class="p-treetable p-treetable-sm"
          :selection-keys.sync="selectedItems"
          @node-select="onNodeSelect"
          @node-unselect="onNodeUnselect"
        >
          <template #header>
            <transition name="slide-fade">
              <div class="header">
                <div class="header-bottom__start">
                  <label class="standart-text"> Добавить файл в </label>
                </div>
                <base-select
                  v-model="selectFolder"
                  :options="folders"
                  size="small"
                  :searchable="false"
                  :preselect-first="true"
                  :allow-empty="false"
                  track-by="name"
                  :custom-label="nameFolder"
                  open-direction="top"
                  :max-height="180"
                  class="header-bottom__second"
                  :show-labels="false"
                />
                <div class="header-bottom__second">
                  <FileUpload
                    ref="upload"
                    mode="basic"
                    :name="file && file.name ? file.name : null"
                    :url="sendUrl"
                    :multiple="false"
                    :auto="false"
                    :max-file-size="10 * 1024 ** 3"
                    choose-label="Выберите файл"
                    :custom-upload="true"
                    :show-cancel-button="true"
                    :invalid-file-size-message="
                      'Вы можете загрузить файл размером до 10ГБ.\n' +
                      'Файлы более 10ГБ можно загрузить через CLI или приложение для работы с S3.'
                    "
                    button-class="p-button-sm"
                    button-style="upload-button"
                    @progress="progressLoad($event)"
                    @select="select($event)"
                    @clear="clearFile($event)"
                    @before-upload="view($event)"
                    @uploader="myUploader"
                  >
                    <template #empty>
                      <p>
                        Вы можете загрузить файл размером до 10ГБ. Файлы более 10ГБ можно загрузить
                        через CLI или приложение для работы с S3.
                      </p>
                    </template>
                  </FileUpload>
                  <Button
                    v-if="fileName"
                    type="button"
                    icon="pi pi-times"
                    class="p-button-danger p-button-sm"
                    @click="$refs.upload.clear()"
                  ></Button>
                  <!--                    @click="clearFile($event)"-->
                </div>
                <div class="header-bottom__second">
                  <plain-button
                    slot="action"
                    color="primary"
                    nowrap
                    @click="newFolder({ storage: tariff, storageid: storageid, folders: folders })"
                  >
                    {{ $t('addFolder') }}
                  </plain-button>
                </div>
                <div class="header-filter">
                  <div class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <InputText v-model="filters['global']" placeholder="Поиск" size="50" />
                  </div>
                </div>
              </div>
            </transition>

            <transition name="slide-fade">
              <div class="header-bottom">
                <div class="header__select">
                  <div v-if="!isBufferUse">
                    <plain-button
                      v-if="isSelected && Object.keys(onlySelectedItems).length"
                      color="primary"
                      @click="clearFilter"
                    >
                      {{ $t('deselect') }}
                    </plain-button>
                    <plain-button v-else color="primary" @click="selectAll">
                      {{ $t('select') }}
                    </plain-button>

                    <!--                  <Checkbox v-model="checkedAll" :binary="true" @click="checkAll" />-->
                  </div>
                  <div
                    v-if="isSelected && Object.keys(onlySelectedItems).length"
                    class="header__select-selected"
                  >
                    <!--                    <div class="p-input-icon-left">-->
                    <div>
                      <label class="standart-text">
                        {{ $tc('object', Object.keys(onlySelectedItems).length) }}
                      </label>
                      <!--                      {{ $tc('object', viewSelected(onlySelectedItems.length)) }}-->
                    </div>
                  </div>
                  <div class="header__select-buttons"></div>
                </div>

                <div class="header-bottom__progress">
                  <div
                    v-if="isSelected && Object.keys(onlySelectedItems).length"
                    class="p-input-icon-left header__select-buttons__btn"
                  >
                    <Button
                      type="button"
                      icon="pi pi-trash"
                      label="Удалить"
                      class="p-button-danger p-button-sm"
                      @click="
                        deleteObjects({
                          storage: storageid,
                          items: onlySelectedItems,
                        })
                      "
                    ></Button>
                    <!--                        @clear-select="clearFilter()"-->
                    <!--                      @click="clearFilter"-->
                  </div>
                  <Button
                    icon="pi pi-refresh"
                    class="p-button-sm"
                    :loading="loader"
                    @click="updateStorage"
                  />
                </div>
              </div>
            </transition>
          </template>
          <Column
            field="name"
            header="Имя"
            :styles="{ width: '60%', 'padding-left': '80px' }"
            :expander="true"
            :sortable="true"
          >
            <template #filter>
              <InputText
                v-model="filters['name']"
                type="text"
                class="p-column-filter"
                placeholder="Filter by name"
              />
            </template>
            <!--                v-tooltip.top="'Информация'"-->
            <template #body="slotProps">
              {{ slotProps.node.data.name }}
            </template>
          </Column>
          <Column field="size" header="Размер" :styles="{ width: '8%' }" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.node.type === 'folder' ? '' : size(slotProps.node.data) }}
            </template>
          </Column>
          <Column field="type" :styles="{ width: '12%' }" header="Дата" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.node.type === 'folder' ? '' : created(slotProps.node.data) }}
            </template>
          </Column>
          <!--          <Column header-style="width: 2rem" body-style="text-align: right">-->
          <Column
            field="actions"
            header="Действия"
            :sortable="false"
            :styles="{ width: '5%' }"
            body-style="text-align: right"
          >
            <template #body="slotProps">
              <div class="action-buttons">
                <Button
                  v-if="slotProps.node.type !== 'folder'"
                  type="linc"
                  icon="pi pi-download"
                  class="p-button-rounded p-button-text"
                  @click="viewInfo(slotProps.node.key)"
                ></Button>
                <Button
                  v-if="slotProps.node.type !== 'folder' && isPublic"
                  type="linc"
                  icon="pi pi-external-link"
                  class="p-button-rounded p-button-text"
                  @click="copyLink(slotProps.node.key)"
                ></Button>
              </div>
            </template>
          </Column>
          <!--          <template #footer>-->
          <!--            <div style="text-align: left">-->
          <!--              <Button icon="pi pi-refresh" />-->
          <!--            </div>-->
          <!--          </template>-->
        </TreeTable>
        <router-view></router-view>
      </page-block-stack>
    </transition>
  </div>
</template>

<script>
// import storeMixin from '../mixins/index';
import breakpoint from '@/utils/breakpoints';
import providerChange from '@/mixins/providerChange';
import { OPStorage } from '@/models/OP/OPStorage';
import PageBlockStack from '@/components/PageBlock/PageBlockStack';
import newFolder from '@/mixins/newFolder';
import TreeTable from 'primevue/treetable';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import popoverFix from '@/mixins/popoverFix';
import Column from 'primevue/column';
import { objectSizeParser } from '@/utils/parsers/objectSizeParser';
import { format } from 'date-fns';
import BaseSelect from '@/components/Select/BaseSelect';
import { saveAs } from 'file-saver';

import Toast from 'primevue/toast';
import deleteObjects from '@/mixins/deleteObjects';
import FileUpload from 'primevue/fileupload';
import Vue from 'vue';
import BaseProgress from '@/components/BaseLoader/BaseProgress';
import viewObject from '@/mixins/viewObject';
import axios from 'axios';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import modals from '@/mixins/modals';

export default {
  name: 'Objects',
  components: {
    // BaseProgress,
    BaseSelect,
    PageBlockStack,
    TreeTable,
    // ObjectContextMenu,
    InputText,
    Toast,
    FileUpload,
    // Tooltip,
    Column,
    Button,
    // Checkbox,
    // TariffsTableObject,
  },
  mixins: [providerChange, newFolder, viewObject, popoverFix, modals, deleteObjects],
  props: {
    storageid: {
      type: String,
      default: '',
    },
    tariff: {
      type: OPStorage,
      required: true,
      validator: obj => obj instanceof OPStorage,
    },
  },
  data() {
    return {
      selectedItems: {},
      lastClickedNode: '',
      nodeSelect: {},
      fileName: '',
      isCheckedFolder: false,
      isPublic: false,
      download: false,
      file: null,
      type: 'determinate',
      totalSize: 100,
      progress: 0,
      doNotCopyInCurrentFolder: false,
      mode: '',
      loader: false,
      s3bufferStorage: {},
      checkedAll: false,
      selectFolder: '',
      filters: {},
      nodes: null,
      folders: [],
      isCurrentLoading: false,
      localDisk: false,
      stats: {},
      isServerError: false,
      tableHead: [
        {
          key: 'icon',
          style: {
            width: '20px',
            maxWidth: '20px',
            minWidth: '20px',
            textAlign: 'right',
          },
        },
        {
          key: 'name',
          label: this.$t('tableHead.name'),
          style: {
            width: '150px',
            maxWidth: '230px',
          },
          // sort: {
          //   prop: 'name',
          //   order: 'asc',
          // },
        },
        {
          key: 'size',
          label: this.$t('tableHead.size'),
          style: {
            width: '150px',
            maxWidth: '200px',
            textAlign: 'center',
          },
        },

        // {
        //   key: 'ssl',
        //   // label: this.$t('tableHead.conditions'),
        //   style: {
        //     width: '44px',
        //   },
        // },
        // {
        //   key: 'member',
        //   // label: this.$t('tableHead.conditions'),
        //   style: {
        //     width: '44px',
        //     textAlign: 'center',
        //   },
        // },
        {
          key: 'date',
          label: this.$t('tableHead.date'),
          style: {
            width: '54px',
          },
          // sort: {
          //   prop: 'operating_status',
          //   order: 'asc',
          // },
        },
        // {
        //   key: 'state',
        //   label: this.$t('tableHead.state'),
        //   style: {
        //     width: '54px',
        //   },
        //   sort: {
        //     prop: 'provisioning_status',
        //     order: 'asc',
        //   },
        // },
        // {
        //   key: 'conditions',
        //   label: this.$t('tableHead.conditions'),
        // style: {
        //   width: '70px',
        //   maxWidth: '70px',
        // },
        // },
        {
          label: this.$t('tableHead.menu'),
          key: 'more',
          style: {
            width: '44px',
            textAlign: 'center',
          },
        },
      ],
      isMobile: true,
    };
  },
  computed: {
    // loading() {
    //   return this.$store.state.moduleStack.serverRequest;
    // },
    // allObjects() {
    //   return this.$store.state.moduleStack.objects[this.tariff.name];
    // },
    // itemsInStorage() {
    //   // let retrievedObject = localStorage.getItem('s3storageBuffer');
    //   // if (retrievedObject) return JSON.parse(retrievedObject);
    //   return JSON.parse(localStorage.getItem('s3storageBuffer'));
    //   // else return null;
    // },
    objects() {
      return this.$store.getters['moduleStack/getObjectsOfCurrentStorage'];
    },
    screenWidth() {
      return this.$store.state.moduleApp.screen.width;
    },
    isSelected() {
      return !!Object.keys(this.selectedItems) && Object.keys(this.selectedItems).length > 0;
    },
    onlySelectedItems() {
      const keys = Object.keys(this.selectedItems);
      if (!keys) return null;
      else {
        const names = keys.filter(key => this.selectedItems[key].checked);
        return names.map(x => {
          const type = this.objects.find(item => x === item.name);
          const obj = {
            name: x,
            type: !type ? 'folder' : 'file',
          };
          // console.log(obj);
          return obj;
        });
      }
    },
    sendUrl() {
      return `https://objects.rusonyxcloud.ru/swiftlk/v1/${this.project}${this.storageid}${
        this.selectFolder.name === '/' ? '' : '/' + this.selectFolder.name
      }/${this.file && this.file.name ? this.file.name : ''} `;
    },
    isBufferUse() {
      if (!this.s3bufferStorage) return null;
      else
        return !!Object.keys(this.s3bufferStorage) && Object.keys(this.s3bufferStorage).length > 0;
    },
    lengthBufferStorage() {
      return this.isBufferUse ? Object.keys(this.s3bufferStorage.items).length : null;
    },
    authOp() {
      return localStorage.getItem('stack');
    },
  },
  watch: {
    nodeSelect: {
      handler: function (event) {
        // console.log('-----nodeSelect-----', event.key, event.parent_id);
        let newKey;
        if (event.parent_id && event.key) {
          newKey = event.key.split('/');
          newKey.pop();
          newKey.join('/');
        }
        // const key = event.parent_id ? event.parent_id.replaceAll('-', '/') : null;
        const key = event.parent_id ? newKey.join('/') : null;

        if (key) {
          // console.log(key);
          const resultArray = [];
          key
            .split('/')
            .forEach((number, index) =>
              resultArray.push((resultArray[index - 1] || '') + '/' + number)
            );
          const result = resultArray.map(x => x.slice(1));
          // console.log(this.selectedItems);
          // console.log(result);

          if (result)
            result.forEach(item => {
              // console.log(this.folders.filter(x => x.type === 'object').map(x => x.name));
              if (
                this.folders
                  .filter(x => x.type === 'object')
                  .map(x => x.name)
                  .includes(item)
              ) {
                // console.log(item);
                this.selectedItems[item].checked = false;
                this.selectedItems[item].partialChecked = true;
              }
            });
        }
      },
      deep: true,
    },
    download: {
      handler: function (event) {
        console.log('this.download', event);
      },
      immediate: true,
    },
    screenWidth: function () {
      this.setIsMobile();
    },
    tariff: {
      handler: function (event) {
        // console.log(event);
        if (event) {
          this.isPublic = event.headers['x-container-read'] === '.r:*,.rlistings';
        }
      },
      immediate: true,
      deep: true,
    },
    progress(event) {
      if (event) {
        // console.log(event);
        // console.log(this.modal);
        if (!this.modal) this.makeModal();
        this.$store.state.moduleStack.progressBar = event;
        Vue.set(this.modal, 'component', BaseProgress);

        Vue.set(this.modal, 'props', {
          value: event,
          text: ' ',
          type: this.type,
          showValue: false,
        });
        // Vue.set(this.modal, 'props', { value: this.progress });
        Vue.set(this.modal, 'size', 'small');
        // Vue.set(this.modal, 'size', 'small');
        Vue.set(this.modal, 'closable', false);
        // Vue.set(this.modal.component, 'props', { value: event, size: this.$store.state.moduleStack.progressBar });
        Vue.set(this.modal, 'footer', false);

        if (event === this.$store.state.moduleStack.totalSize) {
          setTimeout(() => {
            // this.showResModal('Объект загружен.');
            this.getCurrentStorage().then(() => this.$modals.close());
            this.$store.state.moduleStack.progressBar = 0;
            this.$store.state.moduleStack.totalSize = 0;
          }, 500);
        }
      }
    },
    s3bufferStorage: {
      handler: function (event) {
        if (event && event.items) {
          this.mode = event.type;
        } else this.mode = '';
      },
      immediate: true,
    },
    selectedItems: {
      handler: function (event) {
        // console.log('selectedItems', event, this.lastClickedNode);

        if (
          event &&
          this.lastClickedNode &&
          !!this.objects.length &&
          !!this.objects.find(x => x.name === this.lastClickedNode) &&
          this.objects.find(x => x.name === this.lastClickedNode)['content_type'] ===
            'application/directory'
        ) {
          // console.log('is Folder', this.lastClickedNode);
        } else {
          // console.log(
          //   'is Object'
          // this.objects.find(x => x.name === this.lastClickedNode)
          // );
        }

        // if (event && this.isBufferUse) {
        //   const key = Object.keys(event)[0];
        //   const folderToPaste = this.folders.includes(key)
        //     ? `${this.storageid}/${key}`
        //     : `${this.storageid}/`;
        //   this.doNotCopyInCurrentFolder = Object.keys(this.s3bufferStorage.items).includes(
        //     folderToPaste
        //   );
        // }
      },
      immediate: true,
    },
    objects: {
      handler: function (event) {
        this.loader = true;
        // console.log(event);
        let nodes = [];
        if (event) {
          this.folders = [];
          this.folders.push({ name: '/', type: 'object' });
          event.forEach(x => {
            const name = x.name.endsWith('/') ? x.name.slice(0, -1) : x.name;

            const isHaveChildren = name.includes('/');
            const key = name.replaceAll('/', '-');
            const lastIndex = name.lastIndexOf('/');
            const parent = key.slice(0, lastIndex);

            if (
              (!x['content_type'] || x['content_type'] === 'application/directory') &&
              x.bytes === 0
            ) {
              console.log('физически созданная папка', x.name);

              // console.log('name', name);
              if (!this.folders.map(x => x.name).includes(name))
                this.folders.push({ name: name, type: 'object' });
              nodes.push({
                id: name.replaceAll('/', '-'),
                key: name,
                data: {
                  name: name.includes('/') ? name.split('/').at(-1) : name,
                  lastModified: x.lastModified,
                  hash: x.hash,
                  size: x.bytes,
                  contentType: x['content_type'],
                  headers: x.headers,
                },
                type: 'folder',
                parent_id: isHaveChildren ? parent : null,
              });
            } else {
              console.log('файл', x.name);

              if (name.includes('/')) {
                const arr = name.split('/');
                arr.pop();
                // console.log(arr.join('/'));
                const resultArray = [];
                arr
                  .join('/')
                  .split('/')
                  .forEach((number, index) =>
                    resultArray.push((resultArray[index - 1] || '') + '/' + number)
                  );
                const result = resultArray.map(x => x.slice(1));

                result.forEach((x, i) => {
                  if (!this.folders.map(x => x.name).includes(x)) {
                    // console.log(x, result[i - 1]);

                    let parentFolder = x.includes('/') ? result[i - 1].replaceAll('/', '-') : null;
                    this.folders.push({ name: x, type: 'phantom' });
                    nodes.push({
                      name: x.includes('/') ? x.split('/').at(-1) : x,
                      id: x.replaceAll('/', '-'),
                      key: x,
                      data: {
                        name: x.includes('/') ? x.split('/').at(-1) : x,
                        lastModified: null,
                        hash: null,
                        size: null,
                        contentType: null,
                        headers: null,
                      },
                      type: 'folder',
                      parent_id: parentFolder,
                    });
                  }
                });
              }

              console.log(x);
              // console.log(name.includes('/') ? name.split('/').at(-1) : name);
              nodes.push({
                name: name.includes('/') ? name.split('/').at(-1) : name,
                id: name.replaceAll('/', '-'),
                key: name,
                data: {
                  name: name.includes('/') ? name.split('/').at(-1) : name,
                  lastModified: x.lastModified,
                  hash: x.hash,
                  size: x.bytes,
                  contentType: x['content_type'],
                  headers: x.headers,
                },
                type: 'file',
                parent_id: isHaveChildren ? parent : null,
              });
            }
          });
        }
        // console.log(nodes);
        const nest = (items, id = null, link = 'parent_id') =>
          items
            .filter(item => item[link] === id)
            .map(item => ({ ...item, children: nest(items, item.id) }));

        // console.log(nest(nodes));
        this.nodes = nest(nodes);
        this.loader = false;
      },
      immediate: true,
    },
  },
  mounted() {
    this.$nextTick(() => this.setIsMobile());
    this.getBufferStorage();
  },
  beforeRouteLeave(_to, _from, next) {
    this.status = {};
    this.lastClickedNode = '';
    next();
  },
  methods: {
    view(event) {
      console.log(event);
    },
    nameFolder({ name }) {
      return name;
    },
    clearFile(event) {
      this.fileName = '';
      // console.log(event);
    },
    select(event) {
      this.fileName = event && event.files && event.files.length ? event.files[0].name : '';
      let findNameInArray = !!this.objects.find(x => x.name === this.fileName);
      // console.log('findNameInArray', findNameInArray);
      if (findNameInArray) {
        this.showResModal(
          'Объект с таким именем уже существует. <br> При загрузке файл обновит текущий объект.'
        );
      }
      // console.log(event.files[0].name);
    },
    progressLoad(event) {
      console.log(event);
    },

    size(item) {
      return objectSizeParser(item.size);
    },
    showResModal(res) {
      if (!this.modal) this.makeModal();
      Vue.set(this.modal, 'component', null);
      Vue.set(this.modal, 'closable', true);
      Vue.set(this.modal, 'html', true);
      Vue.set(this.modal, 'text', res);
      Vue.set(this.modal, 'footer', {
        centered: true,
        confirm: {
          props: { title: this.$t('confirm') },
          on: {
            click: () => {
              this.$modals.close();
            },
          },
        },
      });
    },

    getBufferStorage() {
      this.s3bufferStorage = Object.assign({}, JSON.parse(localStorage.getItem('s3storageBuffer')));
    },
    async myUploader(event) {
      this.loader = true;
      this.$store.state.moduleStack.totalSize = 0;
      this.$store.state.moduleStack.progressBar = 0;
      // console.log(event.files[0]);
      let formData = new FormData();
      this.file = event.files[0];
      // console.log(this.file);
      this.totalSize = this.file.size;
      // this.type = 'indeterminate';
      // if (this.totalSize < 1000000) this.type = 'indeterminate';
      this.$store.state.moduleStack.totalSize = this.totalSize;
      formData.append(this.file.name, this.file);

      // console.log(this.file);
      // console.log(formData);
      const params = {
        project: this.project,
        storage: this.storageid,
        file: this.file,
        name: `${
          this.selectFolder.name === '/'
            ? this.file.name
            : this.selectFolder.name + '%2F' + this.file.name
        }`,
      };
      await this.onUpload(params).then(data => {
        if (data === 201) {
          this.$toast.add({
            severity: 'success',
            summary: 'Объект загружен',
            detail: this.file.name,
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: 'error',
            summary: 'Ошибка',
            detail: this.file.name,
            life: 3000,
          });
        }
        // console.log(data);
      });
      this.progress = 0;
      this.fileName = '';
      this.loader = false;
      //event.files == files to upload
    },
    viewSelected(item) {
      if (item) return Object.keys(item).length;
      else return 0;
    },
    created(i) {
      return format(i.lastModified, 'dd.MM.yyyy, HH:mm');
    },
    copyLink(item) {
      // console.log(item);
      const url = `https://objects.rusonyxcloud.ru/swiftlk/v1/${this.project}/${this.storageid}/${item}?inline=false`;
      this.$clipboard(url);
      this.showSuccessModal('Ссылка для скачивания успешно скопирована в буфер обмена.');
    },
    showSuccessModal(text) {
      this.$modals.open({
        name: 'SuccessOrder',
        size: 'medium',
        text,
      });
    },
    viewInfo(item) {
      const url = `https://objects.rusonyxcloud.ru/swiftlk/v1/${this.project}/${this.storageid}/${item}?inline=false`;
      const name = item.includes('/') ? item.split('/').at(-1) : item;

      // console.log(name);
      this.viewFile(url, name);
    },

    async viewFile(url, name) {
      this.showLoadingModal('Идет подготовка скачивания...');
      axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
        headers: {
          'x-auth-token': this.authOp,
        },
      })
        .then(response => {
          // console.log(response);
          return response.data;
        })

        .then(res => {
          // console.log(res);
          if (this.$modals) this.$modals.close();
          const blob = new Blob([res]);
          saveAs(blob, name);
          // let _url = window.URL.createObjectURL(blob);
          // window.open(_url, '_blank').focus(); // window.open + focus
        });
    },
    setIsMobile() {
      this.isMobile = this.screenWidth < breakpoint.md;
    },
    putSelected() {
      // console.log('put', this.selectedItems);
      this.clearBuffer();
      this.selectedItems = {};
    },
    onNodeSelect(node) {
      // console.log('NodeSelect', node);
      this.nodeSelect = Object.assign({}, node);
      // console.log('children', node.children.length);
      this.isCheckedFolder = !!node.children.length;
      this.lastClickedNode = node.data.name;
      // this.$toast.add({
      //   severity: 'success',
      //   summary: 'Выбран объект',
      //   detail: node.id,
      //   life: 3000,
      // });
    },
    onNodeUnselect() {
      this.nodeSelect = {};
      this.lastClickedNode = '';
    },
    showLoadingModal(props = {}) {
      if (!this.modal) this.makeModal(props);
      Vue.set(this.modal, 'text', props);
      Vue.set(this.modal, 'title', props);
      Vue.set(this.modal, 'component', BaseLoader);
      Vue.set(this.modal, 'closable', false);
      Vue.set(this.modal, 'footer', false);
    },

    copyToBuffer(type) {
      const buffer = {
        items: {},
        type: type,
      };
      Object.keys(this.selectedItems).forEach(x => {
        let name = `${this.storageid}/${x}`;
        // console.log(name);
        buffer.items[name] = this.objects.find(obj => obj.name === x);
      });
      // console.log(buffer);
      localStorage.setItem('s3storageBuffer', JSON.stringify(buffer));
      this.selectedItems = {};
      this.getBufferStorage();
    },
    clearFilter() {
      this.selectedItems = {};
    },
    async onUpload(payload) {
      const params = {
        onUploadProgress: progressEvent => {
          this.progress = progressEvent.loaded;
        },
        ...payload,
      };
      return this.$store.dispatch('moduleStack/sendFile', params).catch(e => {
        // console.log(e.response.data);
        let error;
        error =
          e.response.data.Code === 'QuotaExceeded'
            ? 'Превышена квота по количеству объектов. Пожалуйста увеличьте квоту или обратитесь в техподдержку.'
            : 'Произошла ошибка, попробуйте повторить позднее, либо обратитесь в техподдержку';
        this.showError(error);
        return 'error';
      });
    },
    clearBuffer() {
      localStorage.removeItem('s3storageBuffer');
      this.getBufferStorage();
      this.selectedItems = {};
    },
    // onNodeSelect(node) {
    //   this.$toast.add({severity:'success', summary: 'Node Selected', detail: node.data.name, life: 3000});
    // },
    selectAll() {
      let selected = {};
      this.objects.forEach(ob => {
        const el = ob.name.endsWith('/') ? ob.name.slice(0, -1) : ob.name;
        selected[el] = {
          checked: true,
          partialChecked: false,
        };
      });
      this.selectedItems = Object.assign({}, selected);
    },
    async updateStorage() {
      await this.getCurrentStorage();
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "addFolder": "Добавить папку",
      "add_file": "Загрузить файлы",
      "object": " Выделено 0 объектов | Выделен {n} объект | Выделены {n} объекта | Выделено {n} объектов",
      "buffer": "0 объектов | {n} объект | {n} объекта | {n} объектов",
      "new": "Создать папку",
      "select": "Выбрать",
      "delete": "Удалить объекты",
      "deselect": "Очистить",
      "tableHead": {
        "name": "Файл",
        "size": "Размер",
        "date": "Дата создания",
        "shared": "Публичная",
        "config": "Конфигурация",
        "conditions": "",
        "status": "Статус",
        "state": "Состояние",
        "port": "Порты",
        "menu": "Меню"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
$vd-aside-size = 260px;

.action-buttons {
  display: flex;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem

  &__select {
    width: 100%;
    margin: 1rem 0.65rem 0.5rem 0.65rem;

    //margin: auto 1rem auto 0.65rem;
    display: flex;
    //justify-content: space-between;
    align-items: center;
    &-selected {
      margin-left: 1rem;
    }

    &-buttons {

      &__btn {
        margin: auto 0.5rem;
      }
    }

  }
}
.header-bottom{
  display flex;
  align-items: center;
  margin-top: 1rem;
  justify-content: flex-start;


  &__start {
    margin auto 1rem
    min-width: 10rem
  }
  &__second {
    margin: auto 1rem
    min-width: 10rem
    max-width: 15rem
    display flex
    flex-direction: row;

  }
  &__progress {
    margin: auto 1rem
    width: 100%
    text-align: end;
  }
}

.vps-detail {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;

  &__loader {
    flexy(center, center);
    min-size(100%);
    flex: 1 1 100%;
  }

  &__inner {
    position: relative;
    +breakpoint(md-and-up) {
      flexy(space-between, flex-start);
    }
  }

  &__content {
    flex: 1 1 100%;
    min-width: 'calc(100% - %s - 1.5rem)' % $vd-aside-size;
  }

  &__aside {
    display: none;

    +breakpoint(md-and-up) {
      display: block;
      flex: 0 0 $vd-aside-size;
      margin-left: 1.5rem;
    }
  }

  &__head {
    margin-bottom: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-bottom: 2.5rem;
    }
  }

  &__context-menu {
    width: calc(100vw - 4rem);

    +breakpoint(sm-and-up) {
      width: 100%;
    }
  }
}
</style>
<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.vps-detail {
  &__popover {
    .popover-inner {
      transform: translateX(-0.75rem)
    }
  }
}
</style>

import Vue from 'vue';
import modals from '@/mixins/modals';
import NewFolder from '@/layouts/Stack/pages/Order/NewFolder';
import showErrorModal from '@/mixins/showErrorModal';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
export default {
  mixins: [modals, showErrorModal],
  data() {
    return {
      folder: '',
      currentStorage: '',
    };
  },
  computed: {
    project() {
      return this.$store.getters['moduleStack/project_id'];
    },
  },
  methods: {
    // newFolder(storage, storageid) {
    newFolder(payload) {
      console.log(payload.storage, payload.storageid, payload.folders);
      this.currentStorage = payload.storage.name;
      console.log(this.currentStorage);
      const that = this;
      return new Promise(() => {
        this.$modals.open({
          name: 'NewFolder',
          size: 'big',
          component: NewFolder,
          closeOnBackdrop: false,
          // title: this.$t('new'),
          props: {
            storage: payload.storage,
            storageid: payload.storageid,
            folders: payload.folders,
          },
          on: {
            change: data => {
              this.folder = data;

              console.log(data);
              // instance = data;
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            },
            notready: () => {
              Vue.set(this.modal.footer.confirm.props, 'disabled', true);
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            confirm: {
              props: { title: this.$t('confirm') },
              on: {
                click: async () => {
                  // console.log(instance);
                  // self.component = BaseLoader;
                  // Vue.set(this.modal.component, 'this.modal');
                  Vue.set(this.modal, 'component', BaseLoader);
                  Vue.set(this.modal.footer.confirm.props, 'loading', true);
                  if (!this.project) await this.checkProject();
                  const payload = {
                    name: `${this.folder}/`,
                  };

                  this.isCreatedContainer(this.folder)
                    .then(async data => {
                      // console.log(data);
                      if (data === 204) {
                        that.$modals.close();
                        this.showError('Такая папка уже есть.');
                      }
                    })
                    .catch(e => {
                      // console.log(e);
                      if (e === 204) {
                        that.$modals.close();
                        this.showError('Такая папка уже есть.');
                      } else if (e === 404) {
                        this.createContainer(payload)
                          .then(async data => {
                            console.log(data);
                            // await this.fetchStoragesForNew();
                            await this.getCurrentStorage();
                            // await this.getCurrentObject();
                            that.$modals.close();
                            this.showResModal(
                              `Папка <b>${this.folder.replaceAll('%2F', '/')}</b> успешно создана.`
                            );
                          })
                          .catch(e => {
                            that.$modals.close();
                            console.log(e);
                            this.showError(e);
                          });
                      }
                      // console.log(e);
                      // this.showError(e);
                    });
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    async checkProject() {
      return await this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'projects');
    },
    async fetchStoragesForNew() {
      return this.$store.dispatch('moduleStack/fetchProjectStorages', this.project).then(data => {
        // console.log(data);
      });
    },
    isCreatedContainer(name) {
      const params = {
        project: this.project,
        storage: `${this.currentStorage}/${name}`,
      };
      return this.$store.dispatch('moduleStack/isCreatedStorage', params);
    },
    async getCurrentStorage() {
      console.log(this.storageid, '---;', this.currentStorage);
      const paramsHead = {
        project: this.project,
        // name: `${this.currentStorage}/${name}`,
        name: this.storageid,
        type: 'head',
      };
      const paramsGet = {
        project: this.project,
        // name: `${this.currentStorage}/${name}`,
        name: this.storageid,
        type: 'get',
      };

      return new Promise(async resolve => {
        await this.$store.dispatch('moduleStack/fetchStorages', paramsGet).then(async data => {
          // console.log(data);
          await this.$store.dispatch('moduleStack/fetchStorages', paramsHead);
          resolve(data);
        });
      });
    },
    async getCurrentObject() {
      const paramsGet = {
        project: this.project,
        storage: this.storageid,
      };
      console.log(paramsGet);
      // return new Promise(async resolve => {
      await this.$store.dispatch('moduleStack/fetchObjects', paramsGet).then(data => {
        // console.log(data);
        // resolve(data);
        // });
      });
    },
    createContainer({ name }) {
      // console.log(type);
      const params = {
        project: this.project,
        storage: `${this.currentStorage}/${name}`,
        // type: type,
      };
      // if (type === '2') return this.$store.dispatch('moduleStack/createStoragePublic', params);
      // else
      return this.$store.dispatch('moduleStack/createStoragePrivate', params);
    },
  },
};

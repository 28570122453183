<template>
  <div class="tariffs-table">
    <base-table :columns="tableHead" :list="paginList" class="tariffs-table__table">
      <!--      @sort-change="onSortChange"-->
      <!--      :sort="sort"-->
      <tariffs-table-pool-row
        v-for="item in paginList"
        :key="item.id"
        :columns="tableHead"
        :item="item"
        @click-more="$emit('click-more', item)"
      />
    </base-table>
    <NewPagination
      :step="pagin.step"
      :start="pagin.start || 0"
      :count="pools.length"
      type="base"
      @set-step="setStep"
      @new-pagination="changePagination"
    />
  </div>
</template>

<script>
import BaseTable from '@/components/Table/BaseTable';
import TariffsTablePoolRow from './TariffsTablePoolRow';
import NewPagination from '@/components/Pagination/NewPagination';

export default {
  name: 'TariffsTablePool',
  components: {
    BaseTable,
    TariffsTablePoolRow,
    NewPagination,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    tableHead: {
      type: Array,
      default: () => [],
    },
    dataset: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      stepOptions: [10, 25, 50, 100],
      pagin: {
        start: 0,
        step: 10,
      },
    };
  },
  computed: {
    pools() {
      return this.$store.getters['moduleStack/GET_SORTED_LIST_POOLS'].filter(
        x => x.loadbalancers[0].id === this.id
      );
    },
    paginList() {
      const { start, step } = this.pagin;
      const end = start + step;
      return this.pools.slice(start, end);
    },
    showPagination() {
      return this.pools.length > this.stepOptions[0];
    },
  },
  watch: {},
  mounted() {},
  methods: {
    setStep(value) {
      this.pagin.step = value;
    },
    changePagination(value) {
      this.pagin.step = value.rows;
      this.pagin.start = value.first;
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "tableHead": {
      "name": "Сеть",
      "type": "Тип",
      "conditions": "Состояние",
      "shared": "Публичная",
      "config": "Конфигурация",
      "status": "Статус",
      "state": "Состояние",
      "subnet": "Подсеть",
      "menu": "Меню"
    },
    "activeUnder": "До {date}",
    "selectAll": "Выбрать все",
    "selected": "Выбрано 0 серверов | Выбран 1 сервер | Выбрано {n} сервера | Выбрано {n} серверов",
    "prolong": "Продлить"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariffs-table {
  width: 100%;

  &__tools {
    flexy(space-between, center);
    margin-bottom: 1.5rem;

    &-end {
      flexy(flex-end, center);
    }

    &-note {
      margin-right: 2.5rem;
    }
  }

  &__pagination {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2.5rem;
    }
  }
}
</style>

import ReplaceSSL from '@/layouts/Stack/components/ReplaceSSL';
import showErrorOpenStackModal from '@/mixins/showErrorOpenStackModal';
import storeMixin from '@/layouts/Stack/mixins/fetch';
import Vue from 'vue';
import modals from '@/mixins/modals';

export default {
  mixins: [showErrorOpenStackModal, storeMixin, modals],
  computed: {
    // listenerId() {
    //   return this.listener.id;
    // },
  },
  methods: {
    replaceSSL: function (tariff) {
      const that = this;
      return new Promise(() => {
        const selfName = 'ReplaceSSL';
        this.$modals.open({
          name: selfName,
          size: 'medium',
          component: ReplaceSSL,
          closeOnBackdrop: false,
          props: {
            tariff: tariff,
          },
          text: this.$t('sure.text'),
          on: {
            'ssl-id': data => {
              // console.log(data);
              tariff = data;
            },
            notready: () => {
              Vue.set(this.modal.footer.confirm.props, 'disabled', true);
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            confirm: {
              props: { title: this.$t('sure.confirm') },
              on: {
                click: () => {
                  Vue.set(this.modal, 'footer', {
                    confirm: {
                      props: { loading: true },
                    },
                  });
                  // console.log(tariff);
                  const params = {
                    id: this.listener.id,
                    listener: {
                      default_tls_container_ref: tariff.container_ref,
                    },
                  };
                  this.$store
                    .dispatch('moduleStack/updateListener', params)
                    .then(data => {
                      // console.log(data);
                      if (data) {
                        this.$modals.close({ name: selfName });
                        this.newFetch();
                        this.showResModal('SSL сертификат успешно заменён.');
                      } else {
                        this.newFetch();
                        this.$modals.close({ name: selfName });
                        this.showResModal('ошибка');
                      }
                    })
                    .catch(() => {})
                    .finally(() => {});
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    // makeModal(props = {}) {
    //   this.$modals.open({
    //     name: 'AddModal',
    //     size: 'small',
    //     onOpen: inst => (this.modal = inst),
    //     onClose: () => (this.modal = null),
    //     onDismiss: () => (this.modal = null),
    //     ...props,
    //   });
    // },
    // showResModal(res, props = {}) {
    //   if (!this.modal) this.makeModal(props);
    //   Vue.set(this.modal, 'component', null);
    //   Vue.set(this.modal, 'closable', true);
    //   Vue.set(this.modal, 'text', res);
    //   Vue.set(this.modal, 'footer', {
    //     centered: true,
    //     confirm: {
    //       props: { title: this.$t('close') },
    //       on: {
    //         click: () => {
    //           this.$modals.close();
    //         },
    //       },
    //     },
    //   });
    // },
  },
};

<template>
  <div class="resize">
    <label class="resize-row standart-text">{{ $t('attach') }}</label>
    <div class="l-col">
      <base-select
        ref="select"
        v-model="ssl"
        :hide-selected="true"
        select-label=""
        :searchable="false"
        :options="sslList"
        size="medium"
        :custom-label="sslNames"
        :allow-empty="false"
        :preselect-first="true"
        class="resize-row-item"
        @input="onChange"
      >
      </base-select>
    </div>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import BaseSelect from '@/components/Select/BaseSelect';

import addContainer from '@/mixins/addContainer';
export default {
  name: 'ReplaceSSL',
  components: { BaseSelect },
  mixins: [setFocus, addContainer],
  props: {
    tariff: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      ssl: [],
    };
  },
  computed: {
    defaultSSL() {
      return this.tariff.default_tls_container_ref.split('/containers/')[1];
    },
    sslList() {
      const list = this.$store.state.moduleStack.containers.filter(x => x.id !== this.defaultSSL);
      if (!list.map(x => x.id).includes('new')) list.push({ name: this.$t('new SSL'), id: 'new' });
      return list;
    },
  },
  watch: {
    ssl: function (event) {
      if (event.id === 'new') {
        this.ssl = {};
        this.addContainer();
      }
    },
  },
  mounted() {},
  methods: {
    sslNames({ name }) {
      return name;
    },
    onChange(event) {
      this.$emit('ssl-id', event);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "attach": "Выберите SSL сертификат",
    "new SSL": "Добавить сертификат",
    "title": {
      "certificate": "Добавить сертификат"
    },
    "sure": {
      "confirm": "Подключить",
      "certificate": "Добавить"
    },
    "notice": "Для вступления в силу - перезагрузите сервер"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.resize {
  text-overflow: unset;
  overflow: visible;

  &-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: .75rem .75rem;
&-input {
  padding: 1.5rem 1.5rem;
}
  }
}
</style>
